<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Assume that the reduction reaction for this experiment calls for
        <number-value :value="massKetone" unit="\text{mg}" />
        of 4-<i>t</i>-butylcyclohexanone. Based on the procedure, how much sodium borohydride (in
        mg) should be used?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\ce{NaBH4}:$"
        append-text="$\text{mg}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LCPL4BQ3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    massKetone() {
      return this.taskState.getValueBySymbol('massKetone').content;
    },
  },
};
</script>
